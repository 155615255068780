<script setup>
import { ref, inject, onMounted } from 'vue'
// import Multiselect from '@vueform/multiselect'
import { sbapibackoffice } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import Dialog from '../../components/Dialog.vue'
// require('dayjs/locale/it')
// import dayjs from 'dayjs'
// import { spinnerStore } from '@/stores/spinner'
// dayjs.locale('it')
// import { navbarStore } from '@/stores/navbar'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import Multiselect from '@vueform/multiselect'

import moment from 'moment'
import 'moment/locale/it'

const toast = inject('$toast')
const spinner = spinnerStore()
const navbar = navbarStore()
const deleteDialog = ref(null)

const visibilities = ref([])
const thisSectionVisible = ref(false)

onMounted(async () => {
  navbar.title = 'Eventi'
  spinner.show()

  try {
    let results = await sbapibackoffice.get('home/events')
    if (results?.data) {
      rows.value = results.data

      for (let row of rows.value) {
        row.linkMediaBackup = row.linkMedia
        row.edited = false

        //startDate is UTC like startDate "2024-12-18T11:48:00" i need to convert it to local time
        row.startDate = moment.utc(row.startDate).local()
      }
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  try {
    let results = await sbapibackoffice.get('home/sections-visibility')
    if (results?.data) {
      visibilities.value = results.data
      thisSectionVisible.value = results.data.find(v => v.sectionName == 'EVENTS').visible
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  spinner.hide()
})

const rows = ref([])

const newRow = () => {
  // if a new box is already present, don't add another one
  if (rows.value.some((box) => !box.id)) {
    toast.warning('Completa l\'evento corrente prima di crearne uno nuovo')
    return
  }
  rows.value.push({
    title: '',
    linkMedia: '',
    startDate: '',
    place: '',
    link: '',
    linkTitle: '',
    info: '',
    edited: true
  })
}

const saveRow = async (row) => {
  // check if all required fields are filled
  if (!row.title || !row.linkMedia || !row.startDate || !row.place || !row.link || !row.linkTitle || !row.info) {
    toast.error('Compila tutti i campi obbligatori')
    return
  }

  // chack link url format
  if (!row.link.match(/^(http|https):\/\/[^ "]+$/)) {
    toast.error('Formato url errato. Utilizzare questo formato: https://ilmiosito.it')
    spinner.hide()
    return
  }

  spinner.show()

  // diferentiate between new and existing box

  if (row.id) {
    // update existing box
    await updateRow(row)
  } else {
    // create new box
    await createRow(row)
  }

  spinner.hide()
}

// base64 to file
const base64ToBlob = (base64, type) => {
  const binStr = atob(base64.split(',')[1])
  const len = binStr.length
  const arr = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }

  return new Blob([arr], { type })
}

const createRow = async (box) => {
  // Creare un oggetto FormData
  const form = new FormData()

  // Aggiungere i campi del payload come stringa
  form.append('payload', JSON.stringify({
    'title': box.title,
    'startDate': box.startDate,
    'place': box.place,
    'link': box.link,
    'linkTitle': box.linkTitle,
    'info': box.info
  }))

  // Aggiungere i file, trasformando le immagini in file
  form.append('fileMedia', base64ToBlob(box.linkMedia, 'image/png'))

  try {
    const response = await sbapibackoffice.post('home/events', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (response?.data) {
      box.id = response.data.id
      box.edited = false
      toast.success('Box creato con successo e aggiunto in fondo alla lista')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const updateRow = async (box) => {
  // Creare un oggetto FormData
  const form = new FormData()

  // Aggiungere i campi del payload come stringa
  form.append('payload', JSON.stringify({
    'title': box.title,
    // 'linkMedia': box.linkMediaBackup,
    'startDate': box.startDate,
    'place': box.place,
    'link': box.link,
    'linkTitle': box.linkTitle,
    'info': box.info
  }))

  // Aggiungere i file, trasformando le immagini in file
  if (box.fileMedia_changed) {
    form.append('fileMedia', base64ToBlob(box.linkMedia, 'image/png'))
  }

  try {
    const response = await sbapibackoffice.put('home/events/' + box.id, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (response?.data) {
      box.edited = false
      toast.success('Box aggiornato con successo')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const boxToBeRemoved = ref(null)
const askRemovePermission = (box) => {
  deleteDialog.value.open()
  boxToBeRemoved.value = box
}

const removeBox = async () => {

  spinner.show()

  try {
    let results = await sbapibackoffice.delete('home/events/' + boxToBeRemoved.value.id)
    if (results?.data) {
      rows.value = rows.value.filter((box) => box.id !== boxToBeRemoved.value.id)
      deleteDialog.value.close()
      toast.success('Box eliminato con successo')
    }
  } catch (error) {
    toast.error('Errore durante l\'eliminazione del box: ' + error.message)
  }

  spinner.hide()
}

const removeMedia = (row) => row.linkMedia = ''
const rowToUpdate = ref(null)

const startUploadMedia = (box) => {
  rowToUpdate.value = box
  document.getElementById('fileInput').click()
}

const uploadMedia = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    rowToUpdate.value.linkMedia = e.target.result
    rowToUpdate.value.edited = true
    rowToUpdate.value.fileMedia_changed = true
  }

  reader.readAsDataURL(file)

  // reset the input value
  event.target.value = ''
}

const toggleVisibility = async (event) => {
  try {
    const sectionId = visibilities.value.find(v => v.sectionName == 'EVENTS').id
    await sbapibackoffice.put(`home/sections-visibility/${sectionId}`, { visible: event.target.checked })
  
    if (event.target.checked) {
      toast.success('Sezione visibile su cving.com')
    } else {
      toast.success('Sezione nascosta su cving.com')
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }
}

const boxInfoValues= [{
  value: 1,
  label: 'Mancano X giorni'
}, {
  value: 2,
  label: 'I posti stanno per terminare'
}, {
  value: 3,
  label: 'SOLD OUT'
}]
</script>

<template>
  <div class="container">
    <div class="cms_description">
      <p>La sezione può essere attiva solo se caricato almeno un evento. Si prevede in ogni caso la spunta di visibilità della sezione.</p>
      <p>I campi<span class="required_symbol">*</span> sono obbligatori</p>
      <!-- switch toggle to hide show the entire cms section -->

      <div class="actions">
        <div class="switch-toggle">
          <input id="switch" v-model="thisSectionVisible" type="checkbox" @change="toggleVisibility">
          <label for="switch">Visibile su cving.com</label>
        </div>
        <div>
          <material-button text="Crea nuovo evento" type="success" @click="newRow" />
        </div>
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th style="text-align: center;">
              <p>Immagine<span class="required_symbol">*</span></p>
              <p>(Dimensioni suggerite: 672x370 px)</p>
            </th>
            <th>Titolo evento<span class="required_symbol">*</span></th>
            <th>Data inizio<span class="required_symbol">*</span></th>
            <th>Luogo<span class="required_symbol">*</span></th>
            <th style="text-align: center;">
              <p>Link<span class="required_symbol">*</span></p>
              <p>(Formato: https://ilmiosito.it)</p>
            </th>
            <th>Titolo campo link<span class="required_symbol">*</span></th>
            <th style="min-width: 200px;">
              Info (Box blu)<span class="required_symbol">*</span>
            </th>
            <th><!-- trash icon --></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))"
            :key="row.id"
          >
            <td class="file_column">
              <div v-if="row.linkMedia" class="image-wrapper">
                <font-awesome-icon
                  class="delete-item-image"
                  icon="trash-can"
                  @click="removeMedia(row)"
                />
                <img :src="row.linkMedia" alt="desktop">
              </div>
              <button v-else class="add-file cursor-pointer" @click="startUploadMedia(row)" v-text="'Carica file'" />
            </td>
            <td> 
              <div class="text_column">
                <input v-model="row.title" type="text" @change="row.edited = true">
              </div>
            </td>
            
            <td class="date_column">
              <Datepicker
                v-model="row.startDate"
                auto-apply
                :enable-time-picker="true"
                :clearable="false"
                format="dd/MM/yyyy HH:mm"
                @update:model-value="row.edited = true"
              />
            </td>
            <td>
              <div class="text_column">
                <input v-model="row.place" type="text" @change="row.edited = true">
              </div>
            </td>
            <td> 
              <div class="text_column">
                <input v-model="row.link" type="text" @change="row.edited = true">
              </div>
            </td>
            <td>
              <div class="text_column">
                <input v-model="row.linkTitle" type="text" @change="row.edited = true">
              </div>
            </td>
            <td class="number_column">
              <!-- 
                Info (box blu) ➝ è una select con le seguenti opzioni di testo da restituire:
                – I posti stanno per terminare
                – SOLD OUT
                <input v-model="row.info" type="text" @change="row.edited = true">
              -->
              <multiselect
                v-model="row.info"
                :options="boxInfoValues"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Seleziona un'opzione"
                @input="row.edited = true"
              />
            </td>
            <td>
              <font-awesome-icon v-if="row.edited" class="saveRow" icon="floppy-disk" @click="saveRow(row)" />
              <font-awesome-icon
                v-else
                class="deleteItem"
                icon="trash-can"
                @click="askRemovePermission(row)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <input id="fileInput" type="file" style="display: none" accept="image/*" @change="uploadMedia">

  <Dialog ref="deleteDialog" title="Vuoi Eliminare?" subtitle="se confermi, il box verrà eliminato" button-text="Conferma" @buttonAction="removeBox" />
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.container { padding: 20px; }

.cms_description {
  color: white;
  font-size: 1rem;
  
  p {
    margin-bottom: 10px;

    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    
    .switch-toggle {
      margin-top: 15px;
      margin-bottom: 30px;
      
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

// .required_symbol {
//   color: red;
//   font-size: 1.5rem;
// }

.table {
  color: white;
  height: calc(100vh - 225px);
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #1a74a3;
      position: sticky;
      top: 0;
      z-index: 100;

      th {
        padding: 10px;
        text-align: center;

        &.number_column {
          width: auto;
          max-width: 82px;
        }

        &.date_column {
          width: 200px;
        }
      }
    }
    tbody {
      user-select: none;
      border-radius: 14px;
      tr {
        border-right: 1px solid #f4f4f438;
        border-left: 1px solid #f4f4f438;
        
        td {
          padding: 20px 10px;
          border-bottom: 1px solid #f4f4f438;
          border-radius: 14px;
          
          input {
            color: white;
          }

          .deleteItem {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(253, 102, 102);
          }

          .saveRow {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(102, 253, 102);
          }

          .dragItem {
            cursor: grab;
            height: auto;
            min-width: 12px;
          }

          .company_column {
            min-width: 200px;
          }

          .text_column {
            width: auto;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.file_column {
            width: auto;
            padding: 0;

            .image-wrapper {
              position:relative;
              width: fit-content;

              img {
                max-width: 300px;
              }
            }

            .add-file {
              max-width: 130px;
              min-width: unset !important;
              overflow: visible !important;
              white-space: normal;
              line-height: unset !important;
              font-size: 10.5px;
              background: var(--info);
              color: white;
              text-transform: uppercase;
              outline: 0;
              box-shadow: none;
              border-radius: 4px;
              border: 2px solid var(--info);
              height: 41px;
              padding: 0 10px;
            }

            .delete-item-image {
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;
              width: 16px;
              height: auto;
              color: rgb(253, 102, 102);
            }
          }

          &.number_column {
            width: auto;
            max-width: 82px;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.date_column {
            width: 200px;
          }
        }
      }
    }
  }
}

// hide number input arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
.date_column input {
  background-color: transparent;
  color: white;
  height: 41px;
}
</style>